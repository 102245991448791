<template>
  <div class="info-history-container center">

    <div class="info-history column">

      <span class="info-history-title">
        <p class="info-history-title-text">학교연혁</p>
      </span>

      <div
      v-for="(item, index) in history"
      :key="'history-'+index">
      <div class="info-history-content">
        <span class="info-history-content-title">
          <p class="default-margin">{{item.year}}'s</p>
        </span>
        <ul class="info-history-content-item">
          <li
          v-for="(subItem, subIndex) in item.content"
          :key="'history-content-'+index+'-'+subIndex">
          {{subItem}}
          </li>
        </ul>
      </div>

      <hr class="info-history-hr">

      </div>


    </div>

  </div>
</template>

<script>
import { history } from '../../data/history';

export default {
  data() {
    return {
      history
    }
  }
}
</script>

<style>

</style>
