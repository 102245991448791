export const history = [

	{
		year: '2024',
		content: [
		'- 2024 산업인력공단 귀국예정 외국인 근로자 취·창업 훈련기관 지정(E-9대상 제과제빵, 커피바리스타 교육 실시)',
		'- 수원도시재단 연무동 도시재생 현장지원센터 카페운영 역량강화 프로그램 운영',
		'- 노보텔 앰배서더 수원 산학협력 업무 협약',
		"- '사랑의 빵 나누기' 사회 공헌 프로젝트 진행(수원 엠마우스 공부방 초청 베이킹 수업)",
		'- 2024 전국 고교생 대상 서울 국제 식음료 외식조리 경연대회 제과 부문 전국 대상, 조리부문 최우수, 바리스타 부문 최우수 영예',
		'- 고용노동부 5년인증(23-27) 우수훈련기관 지정',
		'- 고용노동부 일반고특화 훈련 3개 과정 승인',
		'- 고용노동부 계좌제 승인 교육과정(한식, 양식, 중식자격, 브런치&자연치유, K-디저트카페실무(떡+한과+후식디저트), 수제도시락&케이터링실무, 기초조리+반찬조리실무, 이태리&프랑스 가정식, 제과제빵자격, 시그니처디저트(구움과자&케이크), 시그니처브레드(베이커리카페 제빵실무), 베이커리카페실무(제과제빵), 케이크데코레이션, 커피 바리스타 등)',
		'- 경기 수원 지역 자활센터 반찬조리 직무교육 실시',
		'- 사회복지법인 꿈을 키우는집 요리 위탁교육',
		'- (사)식음료외식조리 협회 커피 바리스타 자격 고사장 지정 운영',
		]
	},


	{
		year: '2023',
		content: [
		'- 고용노동부 일반고 특화 훈련과정 승인',
		'- 평생교육 바우처 등록기관 지정',
		'- 고용노동부 계좌제 승인교육과정(한식,양식,중식, 한식디저트,브런치&자연치유, 반찬실무, 피크닉도시락&홈파티음식, 이태리&프랑스가정식, 제과, 제빵, 케이크데코레이션, 시그니처디저트, 제과현장디저트, 커피바리스타)',
		'- 소상공인시장 진흥공단 희망리턴패키지 운영기관 지정 ( K-프리미엄 디저트 카페 창업, K-비건푸드&샐러드 전문점)',
		'- 수원여자고등학교 베이킹 동아리 CA(특별활동)실시',
		'- 고용노동부 5년 인증 (23-27) 우수훈련기관 지정',
		'- 산업인력공단 귀국예정 외국인 근로자 취·창업 훈련기관 지정(베트남 E-9 근로자 제빵 교육 실시)',
		'- 수원도시재단 연무동 도시재생지원센터 카페운영 역량강화 프로그램 운영',
		'- 오산대학교 산학협력 가족회사 협약',
		'- 한국기술대학교 스마트직업훈련 플랫폼(STEP) 운영기관선정',
		'- 2023 전국 고교생 대상 서울국제 식음료 외식조리 경연대회 제과 부문 전국 최우수·우수 영예',
		'- 일반고특화 1일 카페 창업 수익금 수원시 휴먼 서비스센터 기부',
		'- 경기 수원 자활센터 반찬조리 직무교육실시',
		'- 수원시 휴먼서비스센터 베이킹 직무교육실시',
		'- 해병대 군인대상 요리과정 군집체훈련 실시',
		'- 오산대학교 Job 콘서트&호텔리어 체험 프로그램 운영',
		'- 용인예술과학대 호텔제과제빵학과 체험 프로그램 운영',
		'- 사회복지법인 꿈을 키우는 집 요리 위탁 교육',
		'- 장안고등학교 베이킹 CA(특별활동)',
		'- (사)식음료외식조리협회 커피 바리스타 자격고사장 지정 운영'
		]
	},


	{
	year: '2022',
	content: [
	'- 고용노동부 5년인증(23-27) 우수훈련기관 선정',
	'- 고용노동부 계좌제 승인교육과정(한식조리기능사취득(이론&실기), 한식자격과정(실기), 양식자격속성과정(이론&실기), 중식자격속성과정(이론&실기), 양식·중식 자격취득(이론&실기), 반찬조리실무과정, 브런치&자연치유음식 실무과정, 양식요리(이태리&프랑스 가정식), 전통한식디저트실무(한과&음청류&떡류), 플로럴블랜딩 입문과정(꽃차&잎차&약차))',
	'- 소상공인시장 진흥공단 희망리턴패키지 운영기관 지정'
	]
	},


	{
        year : '2021', 
        content : [
            '- 고용노동부 계좌제 승인교육과정((일반고특화)제과제빵자격취득, 한식자격취득, 양식&중식자격취득, 반찬조리, 이태리&프랑스가정식, 브런치&자연치유음식 실무과정, 제과제빵자격취득, 제빵자격취득, 제과자격취득', 
            '- 고용노동부 일반고특화 훈련과정 승인', 
            '- 소상공인시장진흥공단 재창업패키지 운영', 
        ]
    }, 
    {
        year : '2020', 
        content : [
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정([일반고특화] 제과제빵 자격취득과정, [일반고특화]한식·양식·중식 자격취득과정, [일반고특화] 한식·양식·중식 자격취득&실무과정, 한식조리기능사 취득(이론&실기), 양식·중식조리기능사 취득(이론&실기), 한식조리기능사 취득(실기), 반찬조리실무과정, 제과제빵기능사 취득과정(이론&실기), 제빵기능사 취득(이론&실기), 제과기능사 취득(이론&실기), 브런치&자연치유음식 실무과정)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 일반고특화 훈련과정 승인', 
            '- 소상공인 진흥공단 재창업패키지 훈련과정 승인', 
            '- 중·고등학교 CA(특별활동)실시', 
        ]
    }, 
    {
        year : '2019', 
        content : [
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정((일반고특화) 제과제빵 실무자 양성과정(제과기능사+제빵기능사), 한식조리기능사취득(이론&실기), 한식/양식조리기능사취득(이론&실기), 반찬조리전문가양성과정, 제과제빵기능사취득(이론&실기), 제빵기능사취득(이론&실기), ITQ마스터자격취득&사무행정 취업과정, 사무행정 취업실무과정(워드/엑셀/파워포인트/액세스))', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 일반고특화 훈련과정 승인', 
            '- 재외동포 교육 실시 (H2, F4)', 
            '- 소상공인진흥공단 재창업패키지 훈련과정 승인', 
            '- 고용노동부 이수자평가 A등급 획득', 
        ]
    }, 
    {
        year : '2018', 
        content : [
            '- 고용노동부 이수자평가 A등급 획득', 
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정((일반고특화) 조리 전문가 양성과정, (일반고특화) 제과제빵 실무자 양성과정(제과기능사+제빵기능사), 한식조리기능사취득(이론&실기), 한식/양식조리기능사취득(이론&실기), 반찬조리전문가양성과정, 제과제빵기능사취득(이론&실기), 제빵기능사취득(이론&실기), ITQ마스터자격취득&사무행정 취업과정, 사무행정 취업실무과정(워드/엑셀/파워포인트/액세스))', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 일반고특화 훈련과정 승인', 
            '- 재외동포 교육 실시 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시 제과제빵 (조원중, 수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (조원중, 수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 
            '- 2018년 경기도 도지사상 수상', 
            '- 해병대 군인대상 요리과정 군집체 훈련 실시', 
            '- 소상공인시장진흥공단 재창업패키지 훈련과정 승인', 
        ]
    }, 
    {
        year : '2017', 
        content : [
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정(한식조리기능사취득(이론&실기), 양식조리기능사실기반, 반찬조리전문가양성과정, 제과제빵기능사(이론&실기), 제과 케이크디자이너 양성, ITQ 마스터자격취득&사무행정 취업과정, 사무행정 취업실무과정(워드/엑셀/파워포인트)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 일반고특화 훈련과정 승인', 
            '- 재외동포 교육 실시 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시 제과제빵 (조원중, 수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (조원중, 수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 
            '- 고용노동부 2017년 3년인증 우수훈련기관 선정', 
            '- 2017년 고용노동부 장관상 수상', 
            '- 소상공인시장진흥공단 재창업패키지 훈련과정 승인', 
        ]
    }, 
    {
        year : '2016', 
        content : [
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정(한식조리기능사(이론&실기), 양식조리기능사실기반, 반찬조리전문가/제과제빵기능사(이론&실기), 실용케이크&디저트실무/ITQ와 취업 실무 마스터)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 일반고특화 훈련과정 승인', 
            '- 재외동포 교육 실시 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시 제과제빵 (수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 
            '- 고용노동부 2016년 훈련기관인증평가 3년인증 획득', 
            '- 소상공인시장진흥공단 재창업패키지 훈련과정 승인', 
        ]
    }, 
    {
        year : '2015', 
        content : [
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정(한식, 중식, 양식, 밑반찬, 퓨전외식조리실무/제과제빵기능사, 카페브런치&베이커리창업반/사무행정취업실무, ITQ OA마스터)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 일반고특화 훈련과정 승인', 
            '- 소상공인시장진흥공단 창업학교/경영학교/재창업학교 훈련과정 승인', 
            '- 고용노동부 2015 직업능력우수 훈련기관 선정', 
            '- 소상공인시장진흥공단 창업학교 우수 교육기관 선정', 
            '- 재외동포 교육 실시 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시 제과제빵 (수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 

        ]
    }, 
    {
        year : '2014', 
        content : [
            '- 노동부 직업능력개발 계좌제 훈련 승인 교육 과정(한식, 중식, 양식, 밑반찬/컵케익, 케이크데코레이션실무/컴퓨터기초, 엑셀파워포인트실무, 컴활2급자격증)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 재외동포 교육 실시중 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시 제과제빵 (수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 
            '- 고용노동부 2014 취업성과 우수훈련기관 선정', 
            '- 해병대 군인대상 요리과정 군집체 훈련 실시', 
        ]
    }, 
    {
        year : '2013', 
        content : [
            '- 직업능력개발 계좌제훈련 승인 교육 과정(조리사필기대비반, 단체급식 조리기능사, 반찬조리전문가, 제과제빵기능사, OA취업실무(초급))', 
            '- 근로복지공단 희망 내일찾기 훈련 승인 (조리사취업요리) 산재근로자 단독반 실시', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 노동부 지정 직업능력개발 훈련직종 (설계/제도, 정보통신응용, 재무 및 경영, 사무지원, 음식서비스)', 
            '- 근로복지공단 산재근로자 직업훈련 교육기관 지정', 
            '- 해병대 군인대상 요리 및 컴퓨터 군집체훈련 실시', 
            '- 재외동포 교육 실시중 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시중 제과제빵 (수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 
            '- 경기도 농아인 협회 수원시지부 (침묵과 소통사이) 홈베이킹교실', 
            '- 수원시 휴먼서비스 센터 협약 희망지킴이 지정 교육 기관', 
            '- 취약계층교육 (북한이탈주민 훈련)', 
            '- 중소기업 친화직종 훈련 (생산정보시스템)', 
            '- 법무부 범죄예방부문 법무부 장관상 표창', 
            '- 수원시 청소년 쉼터 요리교실', 
        ]
    }, 
    {
        year : '2012', 
        content : [
            '- 직업능력개발 계좌제훈련 승인 교육 과정 (사무자동화, OA취업실무(초, 중급), 단체급식조리사, 한식, 양식코스창업요리, 제과제빵, 케잌데코레이션, 전산세무2급)', 
            '- 노동부 취약계층 훈련승인 ( 북한이탈주민 베이커리 취업준비반)', 
            '- 중소기업친화직종 훈련승인 (생산정보시스템)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 근로복지공단 희망내일찾기 프로그램 (조리사취업요리)', 
            '- 노동부 지정 직업능력개발 훈련직종 (설계/제도, 정보통신응용, 재무 및 경영, 사무지원, 음식서비스)', 
            '- 근로복지공단 산재근로자 직업훈련 교육기관 지정', 
            '- 해병대 군인대상 요리 및 컴퓨터 군집체훈련 실시', 
            '- 재외동포 교육 실시중 (H2, F4)', 
            '- 중, 고등학교 CA (특별활동) 실시중 제과제빵 (수성중, 영신중, 매향중, 이목중, 숙지중, 수일중, 수성고, 조원고) / 요리 (수성중, 영신중, 이목중, 숙지중, 수성고, 조원고)', 
            '- 취약계층교육 (북한이탈주민 훈련)', 
        ]
    }, 
    {
        year : '2011', 
        content : [
            '- 한국산업인력공단 국가기간전략산업직종 ( 멀티미디어콘텐츠 ) 과정승인', 
            '- 취약계층 훈련승인 ( 북한이탈주민 훈련 "전산경리회계실무") 단독반 실시', 
            '- 법무부 재외동포 기술교육기관 지정 ( 사무자동화 )과정승인', 
            '- 직업능력개발 계좌제훈련 승인 교육 과정 - 전산경리사무실무, 전산세무회계, 사무자동화 산업기사, MCAS자격증대비, 사무자동화, 웹컨텐츠디자인, OA취업실무(초, 중급), 단체급식조리사, 한식, 양식코스 창업요리, 제과제빵, 케잌데코레이션)', 
            '- 노동부 지정 직업능력개발 훈련직종 (설계/제도, 정보통신응용, 재무 및 경영, 사무지원, 음식서비스)', 
            '- 노동부 재직근로자 훈련과정 승인', 
            '- 근로복지공단 산재근로자교육기관 지정', 
            '- CA특별활동 요리과정진행 - 영신중, 송원중, 수성중, 삼일공고, 봉담고', 
            '- CA특별활동 제과제빵과정진행 - 영신중, 수성중, 수일남중, 율전중', 
        ]
    }, 
    {
        year : '2010', 
        content : [
            '- 취약계층 여성가장 제과제빵창업과정 승인', 
            '- 사무자동화, 제과제빵고급, 케익데코레이션, 양식코스 승인', 
            '- 한식4기 수료(실업자)', 
            '- 제과제빵3기 수료(실업자)', 
            '- 전산경리사무실무 7~8기 수료(실업자)', 
            '- 전산경리사무실무9기 실시(실업자)', 
            '- 전산세무회계I 실시(실업자)', 
            '- 한식 5기 실시(실업자)', 
            '- 제과제빵4기 실시(실업자)', 
            '- 케잌데코레이션 1기 실시(실업자)', 
            '- 양식코스요리창업 1기 실시(실업자)', 
            '- 직업능력개발 계좌제훈련 지정교육기관 (승인과정 - 전산경리사무실무, 전산세무회계, 플래시전문가, 인터넷방송제작, 교사양성과정, 한식요리, 제과제빵)', 
            '- 노동부 직업능력시설 지정교육기관 (설계 - 제도, 정보통신응용, 재무 및 경영, 음식서비스)', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정교육기관', 
            '- CA특별활동 요리과정진행중 - 영신중, 고색중, 중앙기독중, 삼일공고', 
            '- CA특별활동 제과제빵과정진행중 - 영신중, 수일여중, 정천중, 조원중', 
        ]
    }, 
    {
        year : '2009', 
        content : [
            '- 한식 3기 과정 실시(실업자)', 
            '- 전산경리실무3기 실시(실업자)', 
            '- 제과제빵2기 실시(실업자)', 
            '- 한식 2기 과정 수료(실업자)', 
            '- 전산경리실무2기 수료(실업자)', 
            '- 제과제빵 1기 과정 수료(실업자)', 
            '- 전산세무회계1기 과정 수료(실업자)', 
            '- 한식1기 과정 수료(실업자)', 
            '- 전산경리실무1기 과정 수료(실업자)', 
            '- 직업능력개발 계좌제훈련 지정교육기관(승인교육과정 - 전산경리사무실무, 전산세무회계, 플래시전문가, 인터넷방송제작, 교사양성과정, 한식요리, 제과제빵', 
            '- 노동부 직업능력시설 지정교육기관 (설계 - 제도, 정보통신응용, 재무 및 경영, 음식서비스)', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정교육기관', 
            '- CA특별활동 요리과정진행 - 영신중, 고색중, 삼일공고', 
            '- CA특별활동 제과제빵과정진행 - 영신중, 수일여중, 상촌중, 조원중', 
        ]
    }, 
    {
        year : '2008', 
        content : [
            '- 일본 나까무라 대학 스시연수', 
            '- 수원시 우만 사회복지관 원생교육', 
            '- 숙지중학교 장애인 제과제빵교육', 
            '- CA 특별활동 (요리) - 숙지중, 삼일공고', 
            '- CA 특별활동 (제과제빵) - 숙지중, 영신중, 상촌중, 율현중, 칠보중', 
            '- 특수반 제과제빵 교육 - 숙지중, 매현중', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정 교육기관', 
            '- 노동부 국비보조 교육기관', 
            '- 수원대학교, 라비돌호텔 산학협력체결', 
            '- 한선생직업전문학교 명칭 변경', 
            '- 노동부 직업능력 시설 지정기관 -(설계 - 제도, 정보 - 통신응용, 재무 및 경영, 음식서비스)', 
            '- 한선생직업전문학교 장소이전 (장안동 사거리)', 
        ]
    }, 
    {
        year : '2007', 
        content : [
            '- 경기도 고용촉진 훈련기관 지정', 
            '- 수원시 고용촉진 훈련 위탁 계약', 
            '- 용인시 고용촉진 훈련 위탁 계약', 
            '- 화성시 고용촉진 훈련 위탁 계약', 
            '- 오산시 고용촉진 훈련 위탁 계약', 
            '- CA 특별활동 (요리) - 숙지중, 정천중, 고색중', 
            '- CA 특별활동 (제과제빵) - 숙지중, 칠보중, 수원정보고', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정 교육기관', 
            '- 노동부 직업능력 시설 지정기관', 
            '- 노동부 국비보조 교육기관', 
            '- 경기도 교육청 대명고 위탁교육기관', 
        ]
    }, 
    {
        year : '2006', 
        content : [
            '- 경기도 고용촉진 훈련기관 지정', 
            '- 수원시 고용촉진 훈련 위탁 계약', 
            '- 화성시 고용촉진 훈련 위탁 계약', 
            '- 용인시 고용촉진 훈련 위탁 계약', 
            '- CA 특별활동 (요리) - 숙지중', 
            '- CA 특별활동 (제과제빵) - 율현중.대평중, 영신중, 호매실중, 수원정보고', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정 교육기관', 
            '- 노동부 직업능력 시설 지정기관', 

        ]
    }, 
    {
        year : '2005', 
        content : [
            '- 경기도 고용촉진 훈련기관 지정', 
            '- 수원시 고용촉진 훈련 위탁 계약', 
            '- 화성시 고용촉진 훈련 위탁 계약', 
            '- 용인시 고용촉진 훈련 위탁 계약', 
            '- 오산시 고용촉진 훈련 위탁 계약', 
            '- CA 특별활동 (요리) - 수성중, 율전중, 호매실중, 고색중', 
            '- CA 특별활동 (제과제빵) - 수성중, 천천중, 수성여중, 고색중', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정 교육기관', 
            '- 노동부 직업능력 시설 지정기관 (음식서비스)', 
        ]
    }, 
    {
        year : '2004', 
        content : [
            '- 경기도 고용촉진 훈련기관 지정', 
            '- 수원시 고용촉진 훈련 위탁 계약', 
            '- 화성시 고용촉진 훈련 위탁 계약', 
            '- 평택시 고용촉진 훈련 위탁 계약', 
            '- CA 특별활동 (요리)', 
            '- CA 특별활동 (제과제빵)', 
            '- 노동부 재직근로자 훈련기관 지정', 
            '- 근로복지공단 지정 교육기관', 
            '- 호주 디칸대학 소믈리에 과정 수료', 
        ]
    }, 
    {
        year : '2003', 
        content : [
            '- 경기 TV 수원방송 출연 (설날특집)', 
            '- CA 특별활동 (요리)', 
            '- CA 특별활동 (제과제빵)', 
        ]
    }, 
    {
        year : '2002', 
        content : [
            '- 한선생 요리 제과제빵 학원 설립', 
            '- 경기 TV 수원방송 출연 (크리스마스 케익)', 
            '- CA 특별활동 (요리)', 
            '- CA 특별활동 (제과제빵)'
        ]
    }, 
]
